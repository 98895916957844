
import Swal from 'sweetalert2'
import { defineComponent } from 'vue';
import { Button, Separation, UserFormModal } from '@/components'

import store from '@/store'

export default defineComponent({
  name : 'UsersConfig',
  components:{
    Button,
    Separation,
    UserFormModal
  },
  data(){
    return{
      users:[],
      userToEdit: {role:{ownerId:null}},
      showNewUserModal: false,
      showEditUserModal: false
    }
  },
  methods:{
    openNewUserModal(){
      this.showNewUserModal = true
    },
    closeNewUserModal(){
      this.showNewUserModal = false
    },
    openEditUserModal(){
      this.showEditUserModal = true
    },
    closeEditUserModal(){
      this.showEditUserModal = false
    },
    async editUser(userId: any){
      await store.dispatch('fetchUser', {userId: userId})
      .then((data) => {
        this.userToEdit = data
        this.openEditUserModal()
      })
    },
    async fetchUsers(){
      await store.dispatch('fetchUsers')
      .then((data) => {
        this.users = data
      })
    },
    async createUser(data: any){
      let vm = this
      await store.dispatch('createUser', data)
      .then((data) => {
        if(data.reusedClient) Swal.fire('',vm.$t('suite_swal_user_error'),'info')
        else Swal.fire('', vm.$t('suite_swal_user_created'),'success')
        this.closeNewUserModal()
        this.fetchUsers()
      })
    },
    async updateUser(data: any){
      let vm = this
      data.userId = this.userToEdit.role.ownerId
      await store.dispatch('updateUser', data)
      .then((data) => {
        Swal.fire('', vm.$t('suite_swal_user_updated'),'success')
        this.closeEditUserModal()
        this.fetchUsers()
      })
    },
    async deleteUser(id: any){
      let vm = this
      Swal.fire({
        title: vm.$t('suite_swal_confirm'),
        text: vm.$t('suite_swal_user_remove_confirm'),
        showCancelButton: true,
        icon:'warning',
        confirmButtonText: vm.$t('suite_swal_user_remove_action'),
        cancelButtonText: vm.$t('suite_action_cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch('deleteUser', {userId:id})
          .then((data) => {
            Swal.fire('', vm.$t('suite_swal_user_deleted'),'success')
            vm.fetchUsers()
          })
        }
      })
    },
    fetchStudies(){
      store.dispatch('getClientStudies',{page:0, pageSize:1000})
    }
  },
  mounted(){
    this.fetchUsers()
    this.fetchStudies()
  }
})
