import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/img/roles/superadmin.svg'
import _imports_1 from '../assets/img/roles/admin.svg'
import _imports_2 from '../assets/img/roles/observer.svg'


const _hoisted_1 = { class: "UsersConfig" }
const _hoisted_2 = { class: "UsersConfig--Box" }
const _hoisted_3 = { class: "UsersConfig--Header" }
const _hoisted_4 = { class: "arrowTitle" }
const _hoisted_5 = { class: "UsersConfig--Users" }
const _hoisted_6 = { class: "UsersConfig--Header" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "UsersConfig--User" }
const _hoisted_9 = { class: "UsersConfig--UserAllInfo" }
const _hoisted_10 = { class: "UsersConfig--Logo" }
const _hoisted_11 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_12 = {
  key: 1,
  src: _imports_1,
  alt: ""
}
const _hoisted_13 = {
  key: 2,
  src: _imports_2,
  alt: ""
}
const _hoisted_14 = { class: "UsersConfig--UserInfo" }
const _hoisted_15 = { class: "UsersConfig--UserInfoName" }
const _hoisted_16 = { class: "UsersConfig--Actions" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Separation = _resolveComponent("Separation")!
  const _component_UserFormModal = _resolveComponent("UserFormModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('suite_dashboard_my_studies')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('suite_users_config_info')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h2", {
            innerHTML: _ctx.$t('suite_users_config_total',{count:_ctx.users.length})
          }, null, 8, _hoisted_7),
          _createVNode(_component_Button, {
            class: "Green",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openNewUserModal()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('suite_users_config_new')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user, index) => {
            return (_openBlock(), _createElementBlock("div", null, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    (user.role.role == 'SUPERADMIN')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                      : _createCommentVNode("", true),
                    (user.role.role == 'ADMIN')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                      : _createCommentVNode("", true),
                    (user.role.role == 'OBSERVER')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_13))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createTextVNode(_toDisplayString(user.name) + " " + _toDisplayString(user.surname) + " ", 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$filters.formatSuiteRole(user.role.role)), 1)
                    ]),
                    _createElementVNode("p", null, _toDisplayString(user.email), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("i", {
                    class: "fas fa-pen",
                    onClick: ($event: any) => (_ctx.editUser(user.role.ownerId))
                  }, null, 8, _hoisted_17),
                  _createElementVNode("i", {
                    class: "far fa-trash-alt",
                    onClick: ($event: any) => (_ctx.deleteUser(user.role.ownerId))
                  }, null, 8, _hoisted_18)
                ])
              ]),
              (index < _ctx.users.length - 1)
                ? (_openBlock(), _createBlock(_component_Separation, {
                    key: 0,
                    style: {"margin":"16px 0"}
                  }))
                : _createCommentVNode("", true)
            ]))
          }), 256))
        ])
      ])
    ]),
    (_ctx.showNewUserModal)
      ? (_openBlock(), _createBlock(_component_UserFormModal, {
          key: 0,
          onClose: _ctx.closeNewUserModal,
          onSaveUser: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createUser($event)))
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showEditUserModal)
      ? (_openBlock(), _createBlock(_component_UserFormModal, {
          key: 1,
          onClose: _ctx.closeEditUserModal,
          onSaveUser: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateUser($event))),
          editUser: _ctx.userToEdit,
          isEditForm: true
        }, null, 8, ["onClose", "editUser"]))
      : _createCommentVNode("", true)
  ]))
}